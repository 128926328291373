import { graphql, useStaticQuery } from 'gatsby';
import {
    KontentMedia,
    KontentStringValue,
} from '../../../../types/KontentProps';
import {
    ProductDetailMastheadData,
    ProductDescription,
    ProductSpecifications,
} from '../../../templates/StructuredDataContext';

export interface wellnessType {
    value: {
        name: string;
        codename: string;
    }[];
}

export interface ProductPreviewData {
    __typename: string;
    product_name_for_display: KontentStringValue;
    elements:
        | ProductDetailMastheadData['elements']
        | ProductSpecifications['elements']
        | ProductDescription['elements'];
    detail_masthead: ProductDetailMastheadData;
    product_specifications: ProductSpecifications;
    product_description: ProductDescription;
    product_display_name?: KontentStringValue;
    sub_title?: KontentStringValue;
    short_description?: KontentStringValue;
    description_image?: KontentMedia;
    gallery_images: {
        value: {
            file: {
                value: {
                    description: string;
                    url: string;
                    name: string;
                }[];
            };
        }[];
    };
}

export interface ProductPagePreview {
    id?: Key | null | undefined;
    elements: {
        slug: {
            value: string;
            url?: string;
        };
        display_page_name: KontentStringValue;
        page_search_image: KontentMedia;
        page_search_summary: KontentStringValue;
        page_components: {
            value: ProductPreviewData[];
        };
        wellness_type: wellnessType;
        wellness_type___de_de: wellnessType;
        wellness_type___fr_fr: wellnessType;
    };
    preferred_language?: string;
}

interface ProductPageQuery {
    allKontentItemLayoutMasterPage: {
        nodes: ProductPagePreview[];
    };
}

export const fragmentProductPagePreview = graphql`
    fragment productPagePreview on kontent_item_layout___master_page {
        id
        elements {
            wellness_type {
                value {
                    codename
                    name
                }
            }
            wellness_type___de_de {
                value {
                    codename
                    name
                }
            }
            wellness_type___fr_fr {
                value {
                    codename
                    name
                }
            }
            slug {
                value
                url
            }
            display_page_name {
                value
            }
            page_search_image {
                ...kontentImage
            }
            page_search_summary {
                value
            }
            page_components {
                value {
                    __typename
                    ...kontentProductDetailMasthead
                    ...kontentProductSpecifications
                    ...kontentProductDescription
                    ...GlobalMasthead
                }
            }
        }
        preferred_language
    }
`;

export const useProducts = (): ProductPagePreview[] => {
    const productPages: ProductPageQuery = useStaticQuery(graphql`
        {
            allKontentItemLayoutMasterPage(
                filter: {
                    elements: {
                        page_type: {
                            value: {
                                elemMatch: { codename: { eq: "product" } }
                            }
                        }
                    }
                    system: {
                        collection: { eq: "authoring" }
                        workflow_step: { ne: "archived" }
                    }
                }
            ) {
                nodes {
                    ...productPagePreview
                }
            }
        }
    `);

    return productPages.allKontentItemLayoutMasterPage.nodes;
};
